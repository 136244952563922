import { FC } from 'react';

import { IconType } from 'react-icons';

import { LinkProps as NextLinkProps } from 'next/link';

import { Container } from './styles';

interface LinkProps extends NextLinkProps {
  title: string;
  icon?: IconType;
  target?: string;
  rel?: string;
}

export const Link: FC<LinkProps> = ({
  title,
  icon: Icon,
  href,
  target,
  rel,
  ...props
}) => {
  return (
    <Container href={href} passHref {...props} target={target} rel={rel}>
      {!!Icon && <Icon />}

      <span>{title}</span>
    </Container>
  );
};
