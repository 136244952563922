import { ValidationError } from 'yup';

export interface ValidationErrors {
  [key: string]: any;
}

export function getValidationErrors(err: ValidationError): ValidationErrors {
  const validationErrors: ValidationErrors = {};

  err.inner.forEach((error) => {
    const splittedPath = error.path.split('.');

    if (splittedPath?.length === 2) {
      validationErrors[splittedPath[0]] = {
        ...validationErrors[splittedPath[0]],
        [splittedPath[1]]: error.message,
      };
    } else {
      validationErrors[error.path] = error.message;
    }
  });

  return validationErrors;
}
