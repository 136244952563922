import { FC, useCallback, useEffect, useState } from 'react';

import Image from 'next/image';

import { Button } from '~/shared/components/Form/Button';

import { Container } from './styles';

interface IRedirectToResaleModalProps {
  redirectToDomain: string;
}

export const RedirectToResaleModal: FC<IRedirectToResaleModalProps> = ({
  redirectToDomain,
}) => {
  const [count, setCount] = useState(5);

  const redirect = useCallback(() => {
    window.open(`${redirectToDomain}?autoSignOut=true`, '_self');
  }, [redirectToDomain]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount((prevCount) => prevCount - 1);
      } else {
        clearInterval(timer);

        redirect();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [count, redirect]);

  return (
    <Container>
      <div className="infos">
        <span>
          Ops, identificamos que você está acessando o portal pelo link
          incorreto.
        </span>
      </div>

      <Image
        src="/images/redirect-icon.png"
        alt="icon"
        width={100}
        height={100}
      />

      <a href={`${redirectToDomain}?autoSignOut=true`}>{redirectToDomain}</a>

      <h1>Você está sendo redirecionado, por favor, aguarde...</h1>
      <h2>
        Se você não for redirecionado em 5 segundos, clique no botão abaixo
      </h2>

      <Button onClick={redirect} disabled={count !== 0}>
        Continuar {count > 0 ? `(${count} segundos)` : ''}
      </Button>
    </Container>
  );
};
