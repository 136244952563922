import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: ${({ theme }) => theme.spacing.xs};
  margin-top: -${({ theme }) => theme.spacing.lg};

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    font-size: 30px;
    font-weight: 700;
    text-align: center;

    & + p {
      margin-top: ${({ theme }) => theme.spacing.xxs};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.onSurface};
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
`;
