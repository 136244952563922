import { NextPage } from 'next';

import { SignIn } from '~/modules/auth/pages/SignIn';

import { withSSRGuest } from '~/shared/utils/withSSRGuest';

export const getServerSideProps = withSSRGuest(async () => {
  return {
    props: {},
  };
});

const SignInPage: NextPage = () => {
  return <SignIn />;
};

export default SignInPage;
