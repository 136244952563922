import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: auto;

  color: ${({ theme }) => theme.colors.secondary};

  transition: all 0.3s;

  span {
    font-size: 14px;
    font-weight: 600;
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing.xs};
    height: 18px;
    width: 18px;
  }

  &:hover {
    opacity: 0.7;
  }

  & + a,
  & + button {
    margin-top: ${({ theme }) => theme.spacing.sm};
  }
` as any;
