import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .infos {
    margin-bottom: 30px;
    background-color: white;
    border-radius: 6px;
    padding: 18px;
    font-weight: bold;
    border: 1px solid #00000026;
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 30px;
  }

  img {
    width: 100px;
  }
`;
