import { FC } from 'react';

import { Container } from './styles';

interface HeaderProps {
  title?: string;
  subtitle: string;
  extraSubtitle?: string;
}

export const Header: FC<HeaderProps> = ({ title, subtitle, extraSubtitle }) => {
  return (
    <Container>
      {!!title && <h2>{title}</h2>}

      <p>{subtitle}</p>
      {extraSubtitle && <small>{extraSubtitle}</small>}
    </Container>
  );
};
